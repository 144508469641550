<template>
  <div class="row mb-4 pb-4">
    <div class="col mb-4">
      <router-link :to="{ name: 'ServicesObjective3'}" class="od_nav_sub">
        Objetivo 3
      </router-link>
    </div>
    <div class="col mb-4">
      <router-link :to="{ name: 'ServicesObjective6'}" class="od_nav_sub">
        Objetivo 6
      </router-link>
    </div>
    <div class="col mb-4">
      <router-link :to="{ name: 'ServicesObjective7'}" class="od_nav_sub">
        Objetivo 7
      </router-link>
    </div>
    <div class="col mb-4">
      <router-link :to="{ name: 'ServicesObjective8'}" class="od_nav_sub">
        Objetivo 8
      </router-link>
    </div>
  </div>
</template>